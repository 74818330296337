import { api } from "api";
import { clauses } from "common/constants";

export const transUtils = {
  uploadAudio: async (audioFile, token) => {
    const formData = new FormData();
    formData.append("audio", audioFile);
    return await api.transcribeAudio(formData, token);
  },

  pollTranscription: async (pollingFunction, token, audioId, onSuccess, setLoader) => {
    return new Promise((resolve, reject) => {
      const intervalID = setInterval(async () => {
        try {
          const pollResponse = await pollingFunction(token, audioId);
          if (!pollResponse) {
            clearInterval(intervalID);
            setLoader(null);
            reject(false);
          }
          if (pollResponse?.transcription) {
            clearInterval(intervalID);
            onSuccess(pollResponse.transcription);
            resolve(true);
          }
        } catch (e) {
          clearInterval(intervalID);
          reject(false);
        }
      }, 2000);
    });
  },

  handleTranscription: async (formik, token, setLoader, setRawData, setProcessedData) => {
    try {
      const audioFile = formik.values.order_audio;
      setLoader(clauses.TRANSCRIBING_AUDIO);
      const data = await transUtils.uploadAudio(audioFile, token);
      const audioId = data?.audio_id;
      if (!audioId) return;
      const rawTranscriptionComplete = await transUtils.pollTranscription(
        api.getRawTranscription, token, audioId,
        (transcription) => {
          setRawData(transcription);
        },
        setLoader,
      );
      if (rawTranscriptionComplete) {
        setLoader(clauses.PROCESSING_TRANSCRIPTION);
        await transUtils.pollTranscription(
          api.getProcessedTranscription, token, audioId,
          (transcription) => {
            setProcessedData(transcription);
            const orderText = formik.values.order_text
              ? `${formik.values.order_text}\n${transcription}`.trim()
              : transcription;
            formik.setFieldValue("order_text", orderText);
          },
          setLoader,
        );
      }
    } catch (error) {
      setLoader(null);
    }
  },
};

